@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

hr {
  @apply border-stone-900;
}

.bobblehead {
  animation-name: bobblehead;
  animation-duration: 0.8s;
  transform-origin: 50% 50%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes bobblehead {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-4deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(4deg);
  }
  30% {
    transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(4deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-4deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(2px, 1px) rotate(-4deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(4deg);
  }
  90% {
    transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-4deg);
  }
}

body {
  @apply no-scrollbar;
}

.grecaptcha-badge {
  z-index: 999;
}

@font-face {
  font-display: swap;
  font-family: "SangBleu Empire";
  font-weight: 400;
  src: url("/fonts/SangBleuEmpire-Regular-WebS.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "SangBleu Empire";
  font-weight: 500;
  src: url("/fonts/SangBleuEmpire-Medium-WebS.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "SangBleu Empire";
  font-weight: 700;
  src: url("/fonts/SangBleuEmpire-Bold-WebS.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Euclid Circular A";
  font-weight: 400;
  src: url("/fonts/EuclidCircularA-Regular-WebS.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Euclid Circular A";
  font-weight: 500;
  src: url("/fonts/EuclidCircularA-Medium-WebS.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Euclid Circular A";
  font-weight: 600;
  src: url("/fonts/EuclidCircularA-Semibold-WebS.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: "Euclid Circular A";
  font-weight: 700;
  src: url("/fonts/EuclidCircularA-Bold-WebS.woff2") format("woff2");
}

.search-bar::placeholder {
  color: #fff;
  font-weight: 500;
}

.search-bar-2::placeholder {
  color: #1c1719;
  font-weight: 500;
}

.mapboxgl-ctrl-compass {
  display: none !important;
}

.kg-gallery-row {
  @apply flex gap-4 py-4 sm:gap-6 md:gap-8;
}

.kg-gallery-image,
.kg-gallery-image img {
  @apply w-full h-full select-none max-h-[62.5vh] object-cover;
}

.blog-post p {
  @apply min-w-[calc(100vw-2rem)] sm:min-w-[unset] max-w-[calc(100vw-2rem)] sm:max-w-[640px] md:min-w-[640px] mx-auto;
}

.blog-post p:first-child {
  @apply sm:text-[18px];
}

.blog-post ul {
  @apply max-w-[calc(85vw-2rem)] sm:max-w-[480px]  md:max-w-[540px] mx-auto list-disc -mt-3;
}

.blog-post hr {
  @apply max-w-screen-sm w-full md:min-w-[640px] mx-auto;
}

.blog-post a:not(.no-underline) {
  @apply underline break-words text-cpink-300;
}

.blog-post {
  @apply max-w-[100vw] overflow-hidden;
}

.blog-post hr ~ * {
  @apply -my-1 text-left;
}

